<template>
  <ManageImgSidebar
    :isVisible="state.manageDetailImgSidebar.isVisible"
    :imgObj="getters.detailImgObj"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
    @ok="changeFile"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import state from '../../state'
import getters from '../../getters'
import ManageImgSidebar from './ManageImgSidebar.vue'
import useManageDetailImgSidebar from '../../useManageDetailImgSidebar'

export default defineComponent({
  components: {
    ManageImgSidebar,
  },
  setup() {
    const {
      turnOffSidebar,
      changeFile,
    } = useManageDetailImgSidebar()

    return {
      state,
      getters,
      turnOffSidebar,
      changeFile,
    }
  },
})
</script>
