<template>
  <BListGroup
    flush
    class="overflow-auto mt-1"
    style="max-height: 480px"
  >
    <BListGroupItem
      v-for="chatRoom in chatRooms"
      :key="chatRoom.idx"
      href="#"
      class="d-flex no-wrap align-items-center"
      active-class=""
      :active="getIsActive(chatRoom.idx)"
      @click="$emit('clickRoom', chatRoom)"
    >
      <BAvatar
        :src="chatRoom.profileImgUrl"
        class="mr-1"
      />
      <div>
        {{ resolveUsername(chatRoom) }} <span v-if="chatRoom.state === 'INACTIVE'">[INACTIVE]</span>
      </div>
    </BListGroupItem>
  </BListGroup>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BListGroup,
  BListGroupItem,
  BAvatar,
} from "bootstrap-vue"

export default defineComponent({
  model: {
    prop: "targetRoom",
    event: "clickRoom",
  },
  components: {
    BListGroup,
    BListGroupItem,
    BAvatar,
  },
  emits: ["clickRoom"],
  props: {
    chatRooms: Array,
    targetRoom: Object,
  },
  setup(props) {
    const getIsActive = roomIdx => props.targetRoom?.idx === roomIdx
    const resolveUsername = roomObj => {
      if (!roomObj.username) {
        return roomObj.inf_name
      }
      return `${roomObj.username} (${roomObj.inf_name})`
    }

    return {
      getIsActive,
      resolveUsername,
    }
  },
})
</script>
