<template>
  <BCard no-body>
    <BTable
      ref="tgOrderTableRef"
      :items="getters.orderFilter.items"
      :fields="fields"
      show-empty
      small
      :per-page="state.orderFilter.perPage"
      :current-page="state.orderFilter.currentPage"
      responsive
      :selectable="state.orderTable.selectable"
      select-mode="multi"
    >
      <template #head(checkbox)>
        <BFormCheckbox
          @input="inputTableCheckbox"
        />
      </template>

      <template #cell(checkbox)="data">
        <BFormCheckbox
          :checked="data.rowSelected"
          @input="inputRowCheckbox(data.item.idx, $event)"
        />
      </template>

      <template #cell(service)="data">
        <TagbySnsAvatar :channel="data.value" />
      </template>

      <template #cell(sns)="data">
        <TagbySnsColumn :sns="data.item.sns" />
      </template>

      <template #cell(idx)="data">
        <TagbyConditionalLink
          :text="data.value"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(member_idx)="data">
        <TagbyConditionalLink
          :text="data.value"
          :href="`/member/inf/detail/${data.value}`"
          :stateList="['TEXT', 'COPY', 'LINK']"
        />
      </template>

      <template #cell(inf_name)="data">
        <TagbyConditionalLink
          :text="data.item.inf.name"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(inf_phone)="data">
        <TagbyConditionalLink
          :text="data.item.inf.phone"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(sns_idx)="data">
        <TagbyConditionalLink
          :text="data.value"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(order_no)="data">
        <TagbyConditionalLink
          :text="data.value"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(amount)="data">
        {{ resolveMoney(calculateAmount(data.item)) }}
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(address)="data">
        {{ resolveAddress(data.item.address, data.item.address_detail) }}
      </template>

      <template #cell(is_settled)="data">
        <BBadge
          v-if="data.value"
          variant="primary"
        >
          정산완료
        </BBadge>
        <BBadge
          v-else-if="!['PAID', 'READY', 'DELIVERY', 'DONE'].includes(data.item.state)"
          variant="secondary"
        >
          정산대상아님
        </BBadge>
        <BBadge
          v-else
          variant="warning"
        >
          정산필요
        </BBadge>
      </template>
    </BTable>
    <BRow>
      <BCol
        cols="6"
        class="d-flex align-items-center justify-content-star pl-2"
      >
        <span class="text-muted">Showing {{ getters.orderFilter.from }} to {{ getters.orderFilter.to }} of {{ getters.orderFilter.of }} entries</span>
      </BCol>
      <BCol
        :cols="6"
        class="d-flex align-items-center justify-content-end"
      >
        <BPagination
          :value="state.orderFilter.currentPage"
          :total-rows="getters.orderFilter.of"
          :per-page="state.orderFilter.perPage"
          align="center"
          size="sm"
          first-number
          last-number
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent, ref, onMounted } from "@vue/composition-api"
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
  BAvatarGroup,
  BAvatar,
  BFormCheckbox,
  BBadge,
} from "bootstrap-vue"
import ComptIdxColumn from "@/components/ComptIdxColumn.vue"
import TagbyDatetimeColumn from "@/components/TagbyDatetimeColumn.vue"
import TagbySnsColumn from "@/components/TagbySnsColumn.vue"
import TagbySnsAvatar from "@/components/TagbySnsAvatar.vue"
import state from "../../state"
import getters from "../../getters"
import useResolveValue from "@/utils/resolveValue"
import TagbyTableAction from "@/components/TagbyTableAction.vue"
import TagbyTableActionItem from "@/components/TagbyTableActionItem.vue"
import TagbyConditionalLink from "@/components/TagbyConditionalLink.vue"
import useOrderTable from "../../useOrderTable"

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
    TagbySnsColumn,
    BRow,
    BCol,
    BPagination,
    BAvatarGroup,
    BAvatar,
    BFormCheckbox,
    BBadge,
    TagbyTableAction,
    TagbyTableActionItem,
    TagbySnsAvatar,
    TagbyConditionalLink,
  },
  setup() {
    const {
      resolveMoney,
      resolveAddress,
    } = useResolveValue()

    const {
      fields,
    } = useOrderTable()

    const tgOrderTableRef = ref()
    state.orderTable.tableRef = tgOrderTableRef

    const inputRowCheckbox = (itemIdx, checked) => {
      const tableRef = tgOrderTableRef.value
      if (tableRef == null) return
      const i = tableRef.items.findIndex(item => item.idx === itemIdx)
      if (checked) {
        tableRef.selectRow(i)
      } else {
        tableRef.unselectRow(i)
      }
    }

    const inputTableCheckbox = checked => {
      const tableRef = tgOrderTableRef.value
      if (tableRef == null) return
      if (checked) {
        tableRef.selectAllRows()
      } else {
        tableRef.clearSelected()
      }
    }

    onMounted(() => {
      state.orderTable.selectable = false
    })

    const calculateAmount = order => {
      const productJson = order.product_json
      const optionJson = order.option_json

      const { price } = productJson
      const shippingFee = productJson.delivery_fee ?? 0
      const additionalShippingFee = productJson.additional_delivery_fee ?? 0

      const extraPrice = optionJson?.extra_price ?? 0

      return price + shippingFee + additionalShippingFee + extraPrice
    }

    return {
      state,
      getters,
      fields,
      resolveMoney,
      tgOrderTableRef,
      inputRowCheckbox,
      inputTableCheckbox,
      calculateAmount,
      resolveAddress,
    }
  },
})
</script>
