export default () => ({
  hasLoadError: false,
  isVisibleErrorReasonModal: false,
  adminPermission: null,
  idx: null,
  timelineList: [],
  campaign: {
    idx: null,
    state: null,
    sns_channel: null,
    created_at: null,
    registered_at: null,
    brand_name: null,
    title: null,
    thumb_img_idx: null,
    step: null,
    manage_step: null,
    snapshot: null,
  },
  adv: {
    idx: null,
    name: null,
    company_name: null,
  },
  product: {
    idx: null,
    hashtag_list: null,
    caution_text: null,
    category_idx: null,
    parent_category: null,
    child_category: null,
    name: null,
    price: null,
    origin_price: null,
    tagby_fee: null,
    vat: null,
    quantity: null,
    old_quantity: null,
    detail_img_idx: null,
    detail_text: null,
    delivery_method: null,
    delivery_days: null,
    delivery_fee: null,
    additional_delivery_fee: null,
    shipment_place_idx: null,
    after_service_phone: null,
    after_service_info: null,
    origin_info_json: null,
    detail_img_has_origin_info: null,
    state: null,
    has_select_option: null,
    delivery_info_text: null,
    additional_shipping_fee_cond: {
      jeju: null,
      island_mountain: null,
    },
    verify_hashtag: null,
  },
  shipmentPlaceList: [],
  optionList: [],
  optionMeta: [],
  orderList: [],
  chatRoomList: [],
  chatList: [],
  infList: [],
  snsList: [],
  postingList: [],
  allowNotification: false,
  updateDetail: {
    isSaving: false,
  },
  syncQuantityModal: {
    isVisible: false,
  },
  fileByIdx: {},
  childCategoryOptions: [],
  manageThumbImgSidebar: {
    isVisible: false,
  },
  manageDetailImgSidebar: {
    isVisible: false,
  },
  orderFilter: {
    currentPage: 1,
    perPage: 10,
    searchText: null,
    state: [],
    needSettled: false,
  },
  downloadOrderExcelButton: {
    isSaving: false,
  },
  settleOrderSidebar: {
    isVisible: false,
    text: null,
    isSaving: false,
    isValid: false,
    settlementAmount: null,
    invalidOrderNoList: null,
  },
  postingFilter: {
    searchText: null,
    state: [],
    currentPage: 1,
    perPage: 10,
  },
  skipPostingModal: {
    isVisible: false,
  },
  confirmPostingModal: {
    isVisible: false,
  },
  registerPostingSidebar: {
    isVisible: false,
    isSaving: false,
    selectedItemList: [],
  },
  resetPostingModal: {
    isVisible: false,
  },
  postingTable: {
    fieldsId: null,
    selectable: true,
    tableRef: null,
  },
  orderTable: {
    fieldsId: null,
    selectable: true,
    tableRef: null,
  },
  setPaidOrderModal: {
    isVisible: false,
  },
  cancelOrderModal: {
    isVisible: false,
    // isSaving: false,
    // isVisibleProgressing: false,
    isFunctionCancel: true,
    progressingList: [],
    completedCount: 0,
    targetCount: 0,
    // READY, PROGRESSING, COMPLETE
    step: 'READY',
  },
})
