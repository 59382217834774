<template>
  <BCard title="교환 / 환불 정보">
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="상담 전화번호">
          <BFormInput
            v-model="state.product.after_service_phone"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
      >
        <BFormGroup label="교환/환불 정보 안내">
          <BFormTextarea
            v-model="state.product.after_service_info"
            :rows="5"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
