<template>
  <BCard
    style="height: 500px"
    no-body
  >
    <BRow class="h-100 no-gutters">
      <BCol cols="4">
        <ChatFilter
          v-model="searchText"
        />
        <ChatRooms
          v-model="targetRoom"
          :chatRooms="filteredChatRooms"
          @clickRoom="$emit('clickRoom', $event)"
        />
      </BCol>
      <BCol
        cols="8"
        class="d-flex flex-column"
      >
        <Chats
          v-if="targetRoom"
          :chats="chats"
          :advReadAt="targetRoom.inf_last_read_at"
          :infReadAt="targetRoom.adv_last_read_at"
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
} from "@vue/composition-api"
import {
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue"
import ChatFilter from "./Filter.vue"
import ChatRooms from "./Rooms.vue"
import Chats from "./Chats.vue"

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    ChatFilter,
    ChatRooms,
    Chats,
  },
  emits: ["clickRoom"],
  props: {
    chatRooms: Array,
    chats: Array,
  },
  setup(props) {
    const searchText = ref()
    const targetRoom = ref({})

    const filteredChatRooms = computed(() => {
      if (!searchText.value) {
        return props.chatRooms
      }
      return props.chatRooms.filter(r => (
        r.username?.includes(searchText.value)
        || r.inf_name?.includes(searchText.value)
      ))
    })

    return {
      searchText,
      filteredChatRooms,
      targetRoom,
    }
  },
})
</script>
