<template>
  <TagbyPrimaryModal
    :visible="state.setPaidOrderModal.isVisible"
    title="주문 입금완료"
    @ok="setPaidOrderList"
    @hidden="turnOffModal"
  >
    <div>
      <div>
        주문 입금완료를 실행하시겠습니까?
      </div>
      <!-- <BFormCheckbox v-model="state.allowNotification">
        <span class="text-danger">
          인플루언서에게 알림 발송
        </span>
      </BFormCheckbox> -->
      <div class="text-info">
        인플루언서에게 알림 발송없음
      </div>
    </div>
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import state from '../../state'
import useSetPaidOrderModal from '../../useSetPaidOrderModal'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
  },
  setup() {
    const {
      turnOffModal,
      setPaidOrderList,
    } = useSetPaidOrderModal()

    return {
      state,
      turnOffModal,
      setPaidOrderList,
    }
  },
})
</script>
