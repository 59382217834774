<template>
  <TagbyPrimaryModal
    :visible="state.skipPostingModal.isVisible"
    title="포스팅 스킵"
    @ok="skipPostingList"
    @hidden="turnOffModal"
  >
    <div>
      <div>
        포스팅 스킵을 실행하시겠습니까?
      </div>
      <!-- <BFormCheckbox v-model="state.allowNotification">
        <span class="text-danger">
          인플루언서에게 알림 발송
        </span>
      </BFormCheckbox> -->
      <div class="text-info">
        인플루언서에게 알림 발송없음
      </div>
    </div>
  </TagbyPrimaryModal>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormCheckbox,
} from 'bootstrap-vue'
import TagbyPrimaryModal from '@/components/TagbyPrimaryModal.vue'
import state from '../../state'
import useSkipPostingModal from '../../useSkipPostingModal'

export default defineComponent({
  components: {
    TagbyPrimaryModal,
    BFormCheckbox,
  },
  setup() {
    const {
      turnOffModal,
      skipPostingList,
    } = useSkipPostingModal()

    return {
      state,
      turnOffModal,
      skipPostingList,
    }
  },
})
</script>
