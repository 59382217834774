<template>
  <TagbySidebarWithButton
    title="포스팅 등록"
    :visible="state.registerPostingSidebar.isVisible"
    width="800px"
    :isValid="isValid"
    :isSaving="state.registerPostingSidebar.isSaving"
    @ok="registerPostingList"
    @cancel="turnOffSidebar"
    @hidden="turnOffSidebar"
  >
    <BTable
      :fields="fields"
      :items="state.registerPostingSidebar.selectedItemList"
    >
      <template #cell(sns)="data">
        <TagbySnsColumn :sns="data.item.sns" />
      </template>

      <template #cell(url)="data">
        <BFormInput
          :value="data.value"
          :state="!!data.value"
          placeholder="URL을 입력해주세요"
          @input="inputUrl(data.item.idx, $event)"
        />
      </template>
    </BTable>

  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BTable,
  BFormInput,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import useRegisterPostingSidebar from '../../useRegisterPostingSidebar'
import state from '../../state'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'

export default defineComponent({
  components: {
    BTable,
    BFormInput,
    TagbySidebarWithButton,
    TagbySnsColumn,
  },
  setup() {
    const {
      turnOffSidebar,
      registerPostingList,
      isValid,
      inputUrl,
    } = useRegisterPostingSidebar()

    const fields = [
      { key: 'sns', label: 'sns' },
      { key: 'url', label: 'url' },
    ]

    return {
      state,
      fields,
      turnOffSidebar,
      registerPostingList,
      isValid,
      inputUrl,
    }
  },
})
</script>
