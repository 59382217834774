import moment from 'moment'
import state from './state'
import getters from './getters'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import downloadFile from '@/utils/downloadFile'

export default () => {
  const { makeToast } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const downloadOrderExcel = () => {
    state.downloadOrderExcelButton.isSaving = true
    api.downloadOrderExcel({
      order_idx_list: getters.orderFilter.items.map(o => o.idx),
    }).then(response => {
      const fileName = `expg_order_${moment().format('YYYYMMDDHHmmss')}.xlsx`
      const file = new File([response.data], fileName, {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      downloadFile(file, fileName)
    }).catch(err => {
      turnOnErrorReasonModal(err)
      makeToast('danger', '엑셀을 다운로드하는데 실패 했습니다')
    }).finally(() => {
      state.downloadOrderExcelButton.isSaving = false
    })
  }

  return {
    downloadOrderExcel,
  }
}
