<template>
  <BCard no-body>
    <BTable
      ref="tgPostingTableRef"
      :items="getters.postingFilter.items"
      :fields="fields"
      show-empty
      small
      :per-page="state.postingFilter.perPage"
      :current-page="state.postingFilter.currentPage"
      responsive
      :selectable="state.postingTable.selectable"
      select-mode="multi"
    >
      <template #head(checkbox)>
        <BFormCheckbox
          @input="inputTableCheckbox"
        />
      </template>

      <template #cell(checkbox)="data">
        <BFormCheckbox
          :checked="data.rowSelected"
          @input="inputRowCheckbox(data.item.idx, $event)"
        />
      </template>

      <template #cell(service)="data">
        <TagbySnsAvatar :channel="data.value" />
      </template>

      <template #cell(sns)="data">
        <TagbySnsColumn :sns="data.item.sns" />
      </template>

      <template #cell(idx)="data">
        <TagbyConditionalLink
          :text="data.value"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(sns_idx)="data">
        <TagbyConditionalLink
          :text="data.value"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(inf_idx)="data">
        <TagbyConditionalLink
          :text="data.value"
          :href="`/member/inf/detail/${data.value}`"
          :stateList="['TEXT', 'COPY', 'LINK']"
        />
      </template>

      <template #cell(inf_name)="data">
        <TagbyConditionalLink
          :text="data.item.inf.name"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(inf_phone)="data">
        <TagbyConditionalLink
          :text="data.item.inf.phone"
          :stateList="['TEXT', 'COPY']"
        />
      </template>

      <template #cell(url)="data">
        <TagbyConditionalLink
          :text="data.value"
          :href="data.value"
          :stateList="['TEXT', 'COPY', 'LINK']"
        />
      </template>

      <template #cell(expired_at)="data">
        <TagbyDateColumn :value="data.value" />
      </template>

      <template #cell(registered_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(confirmed_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </BTable>
    <BRow>
      <BCol
        cols="6"
        class="d-flex align-items-center justify-content-star pl-2"
      >
        <span class="text-muted">Showing {{ getters.postingFilter.from }} to {{ getters.postingFilter.to }} of {{ getters.postingFilter.of }} entries</span>
      </BCol>
      <BCol
        :cols="6"
        class="d-flex align-items-center justify-content-end"
      >
        <BPagination
          :value="state.postingFilter.currentPage"
          :total-rows="getters.postingFilter.of"
          :per-page="state.postingFilter.perPage"
          align="center"
          size="sm"
          first-number
          last-number
        />
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent, ref, onMounted } from '@vue/composition-api'
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
  BLink,
  BAvatarGroup,
  BAvatar,
  BFormCheckbox,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import TagbyDateColumn from '@/components/TagbyDateColumn.vue'
import TagbySnsColumn from '@/components/TagbySnsColumn.vue'
import TagbySnsAvatar from '@/components/TagbySnsAvatar.vue'
import TagbyConditionalLink from '@/components/TagbyConditionalLink.vue'
import state from '../../state'
import getters from '../../getters'
import useResolveValue from '@/utils/resolveValue'
import useSkipPostingModal from '../../useSkipPostingModal'
import useConfirmPostingModal from '../../useConfirmPostingModal'
import TagbyTableAction from '@/components/TagbyTableAction.vue'
import TagbyTableActionItem from '@/components/TagbyTableActionItem.vue'
import usePostingTable from '../../usePostingTable'

export default defineComponent({
  components: {
    BCard,
    BTable,
    ComptIdxColumn,
    TagbyDatetimeColumn,
    TagbySnsColumn,
    TagbyConditionalLink,
    TagbySnsAvatar,
    BRow,
    BCol,
    BPagination,
    BLink,
    BAvatarGroup,
    BAvatar,
    TagbyTableAction,
    TagbyTableActionItem,
    BFormCheckbox,
    TagbyDateColumn,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const {
      resolveMoney,
    } = useResolveValue()

    const {
      getIsDisabled: getIsSkipDisabled,
      turnOnModal: turnOnSkipModal,
    } = useSkipPostingModal()

    const {
      getIsDisabled: getIsConfirmDisabled,
      turnOnModal: turnOnConfirmModal,
    } = useConfirmPostingModal()

    const {
      fields,
    } = usePostingTable()

    const tgPostingTableRef = ref()
    state.postingTable.tableRef = tgPostingTableRef

    const inputRowCheckbox = (itemIdx, checked) => {
      const tableRef = tgPostingTableRef.value
      if (tableRef == null) return
      const i = tableRef.items.findIndex(item => item.idx === itemIdx)
      if (checked) {
        tableRef.selectRow(i)
      } else {
        tableRef.unselectRow(i)
      }
    }

    const inputTableCheckbox = checked => {
      const tableRef = tgPostingTableRef.value
      if (tableRef == null) return
      if (checked) {
        tableRef.selectAllRows()
      } else {
        tableRef.clearSelected()
      }
    }

    onMounted(() => {
      state.postingTable.selectable = false
    })

    return {
      state,
      getters,
      resolveMoney,
      getIsSkipDisabled,
      turnOnSkipModal,
      getIsConfirmDisabled,
      turnOnConfirmModal,
      fields,
      tgPostingTableRef,
      inputRowCheckbox,
      inputTableCheckbox,
    }
  },
})
</script>
