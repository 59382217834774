<template>
  <BCard>
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="배송 방법">
          <TagbySelect
            v-model="state.product.delivery_method"
            :options="deliveryMethodOptions"
            :reduce="opt => opt.value"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="예상 출고 시점">
          <TagbySelect
            v-model="state.product.delivery_days"
            :options="deliveryDaysOptions"
            :reduce="opt => opt.value"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="기본 배송비">
          <TagbyMoneyInput
            :value="state.product.delivery_fee"
            :disabled="isFreeShippingFee"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="기본 배송비 무료 여부">
          <BFormCheckbox
            :checked="isFreeShippingFee"
            @input="inputFreeShippingFee"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="제주 추가배송비">
          <TagbyMoneyInput
            v-model="state.product.additional_shipping_fee_cond.jeju"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="도서산간 추가배송비">
          <TagbyMoneyInput
            v-model="state.product.additional_shipping_fee_cond.island_mountain"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
      >
        <BFormGroup label="배송정보">
          <BFormTextarea
            v-model="state.product.delivery_info_text"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import TagbyMoneyInput from '@/components/TagbyMoneyInput.vue'
import TagbySelect from '@/components/TagbySelect.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BFormCheckbox,
    TagbyMoneyInput,
    TagbySelect,
  },
  setup() {
    const deliveryMethodOptions = [
      { label: '택배', value: 'SHIPMENT' },
    ]
    const deliveryDaysOptions = [
      { label: '당일발송', value: 1 },
      { label: '3일 이내', value: 3 },
      { label: '7일 이내', value: 7 },
    ]
    const isFreeShippingFee = computed(() => state.product.delivery_fee == null)
    const inputFreeShippingFee = value => {
      if (value) {
        state.product.delivery_fee = null
      } else {
        state.product.delivery_fee = 0
      }
    }
    return {
      state,
      getters,
      deliveryMethodOptions,
      deliveryDaysOptions,
      isFreeShippingFee,
      inputFreeShippingFee,
    }
  },
})
</script>
