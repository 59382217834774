import state from './state'

export default () => {
  const turnOnSidebar = () => {
    state.manageThumbImgSidebar.isVisible = true
  }

  const turnOffSidebar = () => {
    state.manageThumbImgSidebar.isVisible = false
  }

  const changeFile = imgObj => {
    state.campaign.thumb_img_idx = imgObj.idx
    state.fileByIdx[imgObj.idx] = imgObj
    turnOffSidebar()
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    changeFile,
  }
}
