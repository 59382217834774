<template>
  <TagbyActionCardV2>
    <UpdateDetailButton />
    <DownloadOrderExcelButton />
  </TagbyActionCardV2>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionCardV2 from '@/components/TagbyActionCardV2.vue'
import UpdateDetailButton from './UpdateDetailButton.vue'
import DownloadOrderExcelButton from './DownloadOrderExcelButton.vue'

export default defineComponent({
  components: {
    TagbyActionCardV2,
    UpdateDetailButton,
    DownloadOrderExcelButton,
  },
  setup() {

  },
})
</script>
