<template>
  <BCard>
    <BRow>
      <BCol
        cols="12"
        md="6"
      >
        <BFormGroup label="SEARCH">
          <BFormInput
            v-model="state.orderFilter.searchText"
            placeholder="검색할 내용을 입력해주세요"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="6"
        md="3"
      >
        <BFormGroup
          label="정산 대기중인 주문만 보기"
        >
          <BFormCheckbox
            v-model="state.orderFilter.needSettled"
            switch
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="6"
        md="3"
      >
        <BFormGroup
          label="정산가능금액"
        >
          {{ resolveMoney(getters.amountAvailableForSettlement) }} 원
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
      >
        <BFormGroup
          label="STATE"
        >
          <BFormCheckboxGroup
            v-model="state.orderFilter.state"
            :options="orderStateOptions"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
  },
  setup() {
    const {
      resolveMoney,
    } = useResolveValue()

    const orderStateOptions = [
      // 'REGISTER',
      // 'ORDERED',
      'PAID',
      'READY',
      'DELIVERY',
      'DONE',
      'CANCELED',
    ]
    return {
      state,
      getters,
      orderStateOptions,
      resolveMoney,
    }
  },
})
</script>
