<template>
  <BCard>
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="IDX">
          <BFormInput
            v-model="state.campaign.idx"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="상태">
          <BFormInput
            v-model="state.campaign.state"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="STEP">
          <BFormInput
            v-model="state.campaign.step"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="MANAGE_STEP">
          <BFormInput
            v-model="state.campaign.manage_step"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        md="8"
      >
        <BFormGroup label="제목">
          <TagbyCopyableInput v-model="state.campaign.title" />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="브랜드명">
          <BFormInput v-model="state.campaign.brand_name" />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        md="8"
      >
        <BFormGroup label="캠페인 진행 SNS">
          <BFormCheckboxGroup
            v-model="state.campaign.sns_channel"
            :options="snsOptions"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="등록일시">
          <TagbyDatetimeInputV3
            v-model="state.campaign.registered_at"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i',
            }"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="생성일시">
          <TagbyDatetimeInputV3
            v-model="state.campaign.created_at"
            :config="{
              enableTime: true,
              dateFormat: 'Y-m-d H:i'
            }"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="10"
      >
        <BFormGroup label="필수 해시태그">
          <BFormTags
            v-model="state.product.hashtag_list"
            :disabled="!state.product.verify_hashtag"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="2"
      >
        <BFormGroup label="해시태그 별도제공">
          <BFormCheckbox
            :checked="!state.product.verify_hashtag"
            @input="inputProductVerifyHashtag"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
      >
        <BFormGroup label="포스팅 안내사항(선택)">
          <BFormTextarea
            v-model="state.product.caution_text"
            placeholder="캠페인 진행시 주의사항 입력"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormTags,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import TagbyDatetimeInputV3 from '@/components/TagbyDatetimeInputV3.vue'
import state from '../../state'
import getters from '../../getters'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import useInput from '../../useInput'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    TagbyDatetimeInputV3,
    TagbySelect,
    TagbyCopyableInput,
    BFormCheckboxGroup,
    BFormTags,
    BFormTextarea,
    BFormCheckbox,
  },
  setup() {
    const {
      inputProductVerifyHashtag,
    } = useInput()

    const genderOptions = [
      'F', 'M',
    ]
    const stateOptions = [
      'WAIT',
      'ACTIVE',
      'WITHDRAW',
      'BAN',
      'BAN_WITHDRAW',
      'BAN_WAIT',
      'SLEEP',
      'INACTIVE',
    ]
    const snsOptions = [
      'INSTAGRAM',
      'BLOG',
    ]
    return {
      state,
      getters,
      genderOptions,
      stateOptions,
      snsOptions,
      inputProductVerifyHashtag,
    }
  },
})
</script>
