<template>
  <BCard title="선택형 옵션">
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
      >
        <BFormGroup>
          <BFormRadioGroup
            v-model="state.product.has_select_option"
            :options="hasSelectOptionOptions"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode && !state.product.has_select_option"
        cols="12"
      >
        <BFormGroup label="재고">
          <TagbyMoneyInput
            v-model="state.product.quantity"
            disabled
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-else
        cols="12"
      >
        <div
          v-for="option in getters.optionWithMeta"
          :key="option.idx"
        >
          {{ option }}
        </div>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
} from 'bootstrap-vue'
import TagbyMoneyInput from '@/components/TagbyMoneyInput.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    TagbyMoneyInput,
  },
  setup() {
    const hasSelectOptionOptions = [
      { text: '설정함', value: true },
      { text: '설정 안함', value: false },
    ]
    return {
      state,
      getters,
      hasSelectOptionOptions,
    }
  },
})
</script>
