<template>
  <TagbySidebarWithButton
    title="이미지 관리"
    :visible="isVisible"
    :isValid="uploadedImg != null"
    :isSaving="isSaving"
    @shown="clearUploadedImg"
    @hidden="$emit('hidden')"
    @cancel="$emit('cancel')"
    @ok="$emit('ok', uploadedImg)"
  >
    <TagbyOverlay
      :show="isUploading"
    >
      <BRow>
        <BCol>
          <BFormGroup>
            <BButton
              variant="outline-primary"
              class="btn-icon mr-1"
              @click="downloadImg"
            >
              <FeatherIcon icon="DownloadIcon" />
            </BButton>
            <BButton
              variant="outline-primary"
              class="btn-icon"
              @click="wakeUpFileInput"
            >
              <FeatherIcon icon="UploadIcon" />
              <BFormFile
                :id="fileId"
                type="file"
                accept="image/*"
                style="display:none"
                @input="inputImgFile"
              />
            </BButton>
          </BFormGroup>
        </BCol>
      </BRow>
      <BRow>
        <BCol>
          <BFormGroup>
            <BImg
              v-if="imgSrc"
              :src="imgSrc"
              fluid
            />
          </BFormGroup>
        </BCol>
      </BRow>
      <slot name="sidebar-bottom" />
    </TagbyOverlay>
  </TagbySidebarWithButton>
</template>

<script>
import {
  defineComponent,
  ref,
  computed,
} from '@vue/composition-api'
import {
  BButton,
  BImg,
  BRow,
  BCol,
  BFormGroup,
  BFormFile,
} from 'bootstrap-vue'
import { v4 as uuid4 } from 'uuid'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyOverlay from '@/components/TagbyOverlay.vue'
import downloadFile from '@/utils/downloadFile'
import uploadFile from '@/utils/uploadFile'
import useToast from '@/utils/toast'

export default defineComponent({
  components: {
    BButton,
    BImg,
    BRow,
    BCol,
    BFormGroup,
    BFormFile,
    TagbySidebarWithButton,
    TagbyOverlay,
  },
  props: {
    imgObj: {
      type: Object,
      default: () => ({}),
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'ok',
  ],
  setup(props) {
    const uploadedImg = ref()
    const isSaving = ref(false)
    const clearUploadedImg = () => {
      uploadedImg.value = null
    }

    const downloadImg = () => {
      if (uploadedImg.value == null) {
        downloadFile(props.imgObj.url, props.imgObj.file_name)
      } else {
        downloadFile(uploadedImg.value.url, uploadedImg.value.name)
      }
    }

    const isUploading = ref(false)
    const fileId = `__${uuid4()}`
    const imgSrc = computed(() => {
      if (uploadedImg.value != null) {
        return uploadedImg.value.url
      }
      if (props.imgObj != null) {
        return props.imgObj.url
      }
      return null
    })

    const wakeUpFileInput = () => {
      document.getElementById(fileId).click()
    }

    const { makeToast } = useToast()
    const inputImgFile = file => {
      isUploading.value = true
      uploadFile(file, 'campaign_info', '')
        .then(result => {
          uploadedImg.value = {
            idx: result.idx,
            url: result.file_url,
            file_name: result.file_name,
          }
        }).catch(() => {
          makeToast('danger', '이미지를 업로드하는데 실패했습니다')
        }).finally(() => {
          isUploading.value = false
        })
    }

    return {
      isSaving,
      clearUploadedImg,
      downloadImg,

      uploadedImg,
      isUploading,

      fileId,
      imgSrc,

      wakeUpFileInput,
      inputImgFile,
    }
  },
})
</script>
