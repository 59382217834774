import {
  computed,
} from "@vue/composition-api"
import state from "./state"

export default () => {
  const fieldGroupListTemplate = [
    {
      text: "주문위주",
      value: "order",
      fields: [
        { key: "idx", label: "idx" },
        { key: "order_no", label: "주문번호" },
        { key: "service", label: "채널", sortable: true },
        { key: "sns", label: "sns" },
        { key: "amount", label: "결제금액" },
        { key: "is_settled", label: "정산여부" },
        { key: "state", label: "상태" },
        { key: "created_at", label: "결제일시" },
      ],
    },
    {
      text: "배송위주",
      value: "delivery",
      fields: [
        { key: "idx", label: "idx" },
        { key: "service", label: "채널", sortable: true },
        { key: "sns", label: "sns" },
        { key: "address", label: "주소" },
        { key: "shipment_company", label: "배송사" },
        { key: "tracking_no", label: "운송장번호" },
        { key: "recipient_name", label: "받는사람" },
        { key: "message", label: "배송메시지" },
        { key: "phone", label: "연락처" },
        { key: "delivery_state", label: "배송상태" },
      ],
    },
    {
      text: "INF위주",
      value: "inf",
      fields: [
        { key: "idx", label: "idx" },
        { key: "sns", label: "sns" },
        { key: "sns_idx", label: "sns_idx" },
        { key: "member_idx", label: "inf_idx" },
        { key: "inf_name", label: "inf_name" },
        { key: "inf_phone", label: "inf_phone" },
      ],
    },
  ]
  const fieldGroupList = computed(() => {
    if (!state.orderTable.selectable) {
      return fieldGroupListTemplate
    }
    return fieldGroupListTemplate.map(fieldGroup => ({
      text: fieldGroup.text,
      value: fieldGroup.value,
      fields: [
        { key: "checkbox" },
        ...fieldGroup.fields,
      ],
    }))
  })
  const fields = computed(() => fieldGroupList.value.find(fieldGroup => fieldGroup.value === state.orderTable.fieldsId).fields)

  return {
    fields,
    fieldGroupList,
  }
}
