import state from './state'
import getters from './getters'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'
import debounce from '@/utils/debounce'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getOrderList,
    getTimelineList,
  } = useFetch()

  const turnOnSidebar = () => {
    state.settleOrderSidebar.text = null
    state.settleOrderSidebar.isVisible = true
    state.settleOrderSidebar.isValid = false
    state.settleOrderSidebar.settlementAmount = 0
    state.settleOrderSidebar.invalidOrderNoList = null
  }
  const turnOffSidebar = () => {
    state.settleOrderSidebar.isVisible = false
  }

  const checkSettleOrder = () => api.checkSettleOrder({
    order_no_list: getters.settleOrderSidebar.orderNoList,
  }).then(() => {
    state.settleOrderSidebar.isValid = true
    state.settleOrderSidebar.settlementAmount = getters.settleOrderSidebar.amount
    state.settleOrderSidebar.invalidOrderNoList = null
  }).catch(err => {
    state.settleOrderSidebar.isValid = false
    state.settleOrderSidebar.settlementAmount = 0
    state.settleOrderSidebar.invalidOrderNoList = err.response.data.data.invalid_order_no_list
  })

  const debouncedCheckSettleOrder = debounce(checkSettleOrder, 1000)

  const inputText = () => {
    state.settleOrderSidebar.isValid = false
    state.settleOrderSidebar.settlementAmount = 0
    debouncedCheckSettleOrder()
  }

  const settleOrder = () => {
    state.settleOrderSidebar.isSaving = true
    return api.settleOrder({
      expg_idx: state.idx,
      order_no_list: getters.settleOrderSidebar.orderNoList,
      amount: state.settleOrderSidebar.settlementAmount,
    }).then(() => {
      makeToast('primary', '정산에 성공했습니다')
      getOrderList()
      getTimelineList()
      turnOffSidebar()
    }).catch(err => {
      makeToast('danger', '정산에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.settleOrderSidebar.isSaving = false
    })
  }

  return {
    turnOnSidebar,
    turnOffSidebar,
    inputText,
    settleOrder,
  }
}
