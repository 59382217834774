import state from './state'
import api from './api'
import useErrorReason from './useErroReason'
import useToast from '@/utils/toast'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const {
    getDetail,
    getProduct,
    getTimelineList,
  } = useFetch()

  const updateDetail = () => {
    state.updateDetail.isSaving = true
    return api.updateDetail({
      expg: state.campaign,
      product: state.product,
    }).then(() => {
      makeToast('primary', '정보 수정에 성공했습니다')
      getDetail()
      getProduct()
      getTimelineList()
    }).catch(err => {
      makeToast('danger', '정보 수정에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.updateDetail.isSaving = false
    })
  }

  return {
    updateDetail,
  }
}
