import axiosFrame from "@/utils/axiosFrame"

export default {
  getAdminMe: axiosFrame("get", "/manage/admin/me/", null),
  createMemo: axiosFrame("post", "/manage/memo/create-memo/", null),
  getDetail: axiosFrame("get", "/manage/expg-re/detail/", null),
  getProduct: axiosFrame("get", "/manage/expg-re/product/", null),
  getOptionList: axiosFrame("get", "/manage/expg-re/option-list/", null),
  getOrderList: axiosFrame("get", "/manage/expg-re/order-list/", null),
  getAdv: axiosFrame("get", "/manage/expg-re/adv/", null),
  getChatRoomList: axiosFrame("get", "/manage/expg-re/chat-room-list/", null),
  getChatList: axiosFrame("get", "/manage/expg-re/chat-list/", null),
  getTimelineList: axiosFrame("get", "/manage/expg-re/timeline-list/", null),
  getShipmentPlaceList: axiosFrame("get", "/manage/expg-re/shipment-place-list/", null),
  getInfList: axiosFrame("get", "/manage/expg-re/inf-list/", null),
  getSnsList: axiosFrame("get", "/manage/expg-re/sns-list/", null),
  getPostingList: axiosFrame("get", "/manage/expg-re/posting-list/", null),
  getCategoryList: axiosFrame("get", "/manage/expg-re/category-list/", null),
  getFileList: axiosFrame("get", "/manage/expg-re/file-list/", null),
  getOptionMeta: axiosFrame("get", "/manage/expg-re/option-meta/", null),
  downloadOrderExcel: axiosFrame("post", "/manage/expg-re/download-order-excel/", "blob"),
  checkSettleOrder: axiosFrame("post", "/manage/expg-re/check-settle-order/", null),
  settleOrder: axiosFrame("post", "/manage/expg-re/settle-order/", null),
  skipPostingList: axiosFrame("post", "/manage/expg-re/skip-posting-list/", null),
  confirmPostingList: axiosFrame("post", "/manage/expg-re/confirm-posting-list/", null),
  registerPostingList: axiosFrame("post", "/manage/expg-re/register-posting-list/", null),
  resetPostingList: axiosFrame("post", "/manage/expg-re/reset-posting-list/", null),
  updateDetail: axiosFrame("post", "/manage/expg-re/update-detail/", null),
  setPaidOrderList: axiosFrame("post", "/manage/expg-re/set-paid-order-list/", null),
  cancelOrderList: axiosFrame("post", "/manage/expg-re/cancel-order-list/", null),
  cancelOrder: axiosFrame("post", "/manage/expg-re/cancel-order/", null),
}
