<template>
  <TagbyDetailLayout :hasLoadError="state.hasLoadError">
    <template #content>
      <TagbyTabs :tabList="tabList">
        <template #tab(campaign)>
          <ExpgCard />
        </template>

        <template #tab(product)>
          <ProductCard />
        </template>

        <template #tab(price)>
          <PriceCard />
        </template>

        <template #tab(shipment-info)>
          <ShipmentInfoCard />
        </template>

        <template #tab(refund)>
          <AfterserviceCard />
        </template>

        <template #tab(order)>
          <OrderList />
        </template>

        <template #tab(posting)>
          <PostingList />
        </template>

        <template #tab(etc)>
          <EtcCard />
          <ChatCard
            :chatRooms="state.chatRoomList"
            :chats="state.chatList"
            @clickRoom="clickChatRoom"
          />
        </template>
      </TagbyTabs>
    </template>

    <template #side>
      <ActionCard />
      <TagbyTimelinesV3
        v-if="!getters.isCreatingMode"
        :timelineList="state.timelineList"
      />
    </template>

    <template #modal>
      <ErrorReasonModal />
      <CancelOrderModal />
      <SkipPostingModal />
      <ConfirmPostingModal />
      <ResetPostingModal />
      <SetPaidOrderModal />
    </template>

    <template #sidebar>
      <SettleOrderSidebar />
      <ManageThumbImgSidebar />
      <ManageDetailImgSidebar />
      <RegisterPostingSidebar />
    </template>
  </TagbyDetailLayout>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import { getRouterIdx } from "@/utils/router"
import TagbyTabs from "@/components/TagbyTabs.vue"
import TagbyDetailLayout from "@/components/TagbyDetailLayout.vue"
import TagbyTimelinesV3 from "@/components/TagbyTimelinesV3.vue"
import ActionCard from "./components/ActionCard/Main.vue"
import useFetch from "./useFetch"
import ErrorReasonModal from "./components/Modals/ErrorReasonModal.vue"
import state from "./state"
import getters from "./getters"
import useState from "./useState"
import ExpgCard from "./components/ExpgCard/Main.vue"
import ProductCard from "./components/ProductCard/Main.vue"
import ManageThumbImgSidebar from "./components/Sidebars/ManageThumbImgSidebar.vue"
import ManageDetailImgSidebar from "./components/Sidebars/ManageDetailImgSidebar.vue"
import PriceCard from "./components/PriceCard/Main.vue"
import ShipmentInfoCard from "./components/ShipmentInfoCard/Main.vue"
import EtcCard from "./components/EtcCard/Main.vue"
import OrderList from "./components/OrderList/Main.vue"
import SettleOrderSidebar from "./components/Sidebars/SettleOrderSidebar.vue"
import PostingList from "./components/PostingList/Main.vue"
import CancelOrderModal from "./components/Modals/CancelOrderModal.vue"
import SkipPostingModal from "./components/Modals/SkipPostingModal.vue"
import ConfirmPostingModal from "./components/Modals/ConfirmPostingModal.vue"
import RegisterPostingSidebar from "./components/Sidebars/RegisterPostingSidebar.vue"
import ResetPostingModal from "./components/Modals/ResetPostingModal.vue"
import SetPaidOrderModal from "./components/Modals/SetPaidOrderModal.vue"
import AfterserviceCard from "./components/AfterserviceCard/Main.vue"
import ChatCard from "./components/ChatCard"
import useChat from "./hooks/useChat"

export default defineComponent({
  components: {
    TagbyDetailLayout,
    TagbyTabs,
    ActionCard,
    TagbyTimelinesV3,
    ErrorReasonModal,
    ExpgCard,
    ProductCard,
    ManageThumbImgSidebar,
    ManageDetailImgSidebar,
    PriceCard,
    ShipmentInfoCard,
    EtcCard,
    OrderList,
    SettleOrderSidebar,
    PostingList,
    CancelOrderModal,
    SkipPostingModal,
    ConfirmPostingModal,
    RegisterPostingSidebar,
    ResetPostingModal,
    SetPaidOrderModal,
    AfterserviceCard,
    ChatCard,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    state.idx = getRouterIdx()

    const {
      getDetail,
      getTimelineList,
      getAdv,
      getProduct,
      getShipmentPlaceList,
      getOptionList,
      getOrderList,
      getChatRoomList,
      getInfList,
      getSnsList,
      getFileList,
      getOptionMeta,
      getPostingList,
    } = useFetch()

    const {
      clickChatRoom,
    } = useChat()

    if (!getters.isCreatingMode) {
      getDetail()
      getTimelineList()
      getAdv()
      getProduct()
      getShipmentPlaceList()
      getOptionList()
      getOrderList()
      getChatRoomList()
      getInfList()
      getSnsList()
      getFileList()
      getOptionMeta()
      getPostingList()
    }

    const tabList = [
      { key: "campaign", title: "캠페인" },
      { key: "product", title: "상품", visible: !getters.isCreatingMode },
      { key: "price", title: "가격 및 옵션", visible: !getters.isCreatingMode },
      { key: "shipment-info", title: "배송 정보", visible: !getters.isCreatingMode },
      { key: "refund", title: "교환/환불 정보", visible: !getters.isCreatingMode },
      { key: "order", title: "주문 내역", visible: !getters.isCreatingMode },
      { key: "posting", title: "포스팅", visible: !getters.isCreatingMode },
      { key: "etc", title: "기타", visible: !getters.isCreatingMode },
    ]

    return {
      tabList,
      state,
      getters,
      clickChatRoom,
    }
  },
})
</script>
