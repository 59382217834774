<template>
  <BCard>
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="IDX">
          <BFormInput
            v-model="state.product.idx"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="상품명">
          <TagbyCopyableInput
            v-model="state.product.name"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="상위 카테고리">
          <TagbySelect
            v-model="state.product.parent_category"
            :options="parentCategoryOptions"
            :reduce="opt => opt.value"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="하위 카테고리">
          <TagbySelect
            :value="childCategory"
            :options="childCategoryOptions"
            @input="inputChildCategory"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
      >
        <BFormGroup label="상세 설명">
          <BFormTextarea
            v-model="state.product.detail_text"
            placeholder="상품 상세 설명 입력"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="6"
        sm="6"
        md="4"
      >
        <BFormGroup label="썸네일 이미지">
          <BButton
            variant="outline-primary"
            @click="turnOnManageThumbImgSidebar"
          >
            <FeatherIcon icon="ImageIcon" />
          </BButton>
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="6"
        sm="6"
        md="4"
      >
        <BFormGroup label="설명 이미지">
          <BButton
            variant="outline-primary"
            @click="turnOnManageDetailImgSidebar"
          >
            <FeatherIcon icon="ImageIcon" />
          </BButton>
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        class="mt-1"
      >
        <BFormGroup>
          <BFormCheckbox v-model="state.product.detail_img_has_origin_info">
            상품상세 이미지에 상품정보제공고시 내용이 포함되어있습니다.
          </BFormCheckbox>
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
      >
        <BFormGroup>
          <BFormTextarea
            v-model="state.product.origin_info_json"
            :disabled="state.product.detail_img_has_origin_info"
            placeholder="상품정보제공고시 입력"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'
import TagbySelect from '@/components/TagbySelect.vue'
import TagbyCopyableInput from '@/components/TagbyCopyableInput.vue'
import useManageThumbImgSidebar from '../../useManageThumbImgSidebar'
import useManageDetailImgSidebar from '../../useManageDetailImgSidebar'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormCheckbox,
    TagbySelect,
    TagbyCopyableInput,
  },
  setup() {
    const parentCategoryOptions = [
      { label: '제품', value: 'PRODUCT' },
    ]
    const {
      turnOnSidebar: turnOnManageThumbImgSidebar,
    } = useManageThumbImgSidebar()

    const {
      turnOnSidebar: turnOnManageDetailImgSidebar,
    } = useManageDetailImgSidebar()

    const childCategoryOptions = [
      { label: '화장품', value: 95 },
      { label: '의류', value: 96 },
      { label: '구두/신발', value: 96 },
      { label: '가방', value: 96 },
      { label: '패션잡화(모자,벨트,액세서리)', value: 96 },
      { label: '귀금속/보석/시계류', value: 96 },
      { label: '영유아용품', value: 96 },
      { label: '식품(농수산물)', value: 98 },
      { label: '가공식품', value: 98 },
      { label: '건강기능식품', value: 98 },
      { label: '주방용품', value: 99 },
      { label: '침구류/커튼', value: 100 },
      { label: '가구(침대,소파,싱크대,DIY제품)', value: 100 },
      { label: '영상가전(TV류)', value: 101 },
      { label: '가정용 전기제품(냉장고,세탁기,식기세척기,전자레인지)', value: 101 },
      { label: '계절가전(에어컨,온풍기)', value: 101 },
      { label: '사무용기기(컴퓨터,노트북,프린터)', value: 101 },
      { label: '광학기기(디지털카메라,캠코더)', value: 101 },
      { label: '소형전자(MP3,전자사전 등)', value: 101 },
      { label: '휴대폰', value: 101 },
      { label: '내비게이션', value: 101 },
      { label: '의료기기', value: 99 },
      { label: '악기', value: 106 },
      { label: '스포츠용품', value: 106 },
      { label: '자동차용품(자동차부품,기타 자동차용품)', value: 103 },
      { label: '서적', value: 106 },
      { label: '상품권/쿠폰', value: 106 },
      { label: '기타', value: 106 },
    ]

    const childCategory = computed(() => childCategoryOptions.find(o => o.label === state.product.child_category))

    const inputChildCategory = item => {
      state.product.child_category = item.label
      state.product.category_idx = childCategory.value.value
    }

    return {
      state,
      getters,
      parentCategoryOptions,
      childCategoryOptions,
      childCategory,
      turnOnManageThumbImgSidebar,
      turnOnManageDetailImgSidebar,
      inputChildCategory,
    }
  },
})
</script>
