import {
  computed,
} from '@vue/composition-api'
import state from './state'

export default () => {
  const fieldGroupListTemplate = [
    {
      text: '포스팅위주',
      value: 'posting',
      fields: [
        { key: 'idx', label: 'idx' },
        { key: 'service', label: '채널', sortable: true },
        { key: 'sns', label: 'sns' },
        { key: 'url', label: 'url' },
        { key: 'state', label: '상태' },
      ],
    },
    {
      text: '날짜위주',
      value: 'date',
      fields: [
        { key: 'idx', label: 'idx' },
        { key: 'sns', label: 'sns' },
        { key: 'expired_at', label: '만료날짜' },
        { key: 'registered_at', label: '등록일시' },
        { key: 'confirmed_at', label: '확인일시' },
      ],
    },
    {
      text: 'INF위주',
      value: 'inf',
      fields: [
        { key: 'idx', label: 'idx' },
        { key: 'sns', label: 'sns' },
        { key: 'sns_idx', label: 'sns_idx' },
        { key: 'inf_idx', label: 'inf_idx' },
        { key: 'inf_name', label: 'inf_name' },
        { key: 'inf_phone', label: 'inf_phone' },
      ],
    },
  ]
  const fieldGroupList = computed(() => {
    if (!state.postingTable.selectable) {
      return fieldGroupListTemplate
    }
    return fieldGroupListTemplate.map(fieldGroup => ({
      text: fieldGroup.text,
      value: fieldGroup.value,
      fields: [
        { key: 'checkbox' },
        ...fieldGroup.fields,
      ],
    }))
  })
  const fields = computed(() => fieldGroupList.value.find(fieldGroup => fieldGroup.value === state.postingTable.fieldsId).fields)

  return {
    fields,
    fieldGroupList,
  }
}
