import useToast from "@/utils/toast"
import { indexing } from "@/utils/value"
import state from "./state"
import getters from "./getters"
import api from "./api"
import useErrorReason from "./useErroReason"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const getAdminMe = () => api.getAdminMe().then(response => {
    state.adminPermission = response.data.data.permission
  })

  const getDetail = () => api.getDetail({
    expg_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.campaign.idx = resData.idx
    state.campaign.state = resData.state
    state.campaign.sns_channel = resData.sns_channel
    state.campaign.created_at = resData.created_at
    state.campaign.registered_at = resData.registered_at
    state.campaign.brand_name = resData.brand_name
    state.campaign.title = resData.title
    state.campaign.thumb_img_idx = resData.thumb_img_idx
    state.campaign.step = resData.step
    state.campaign.manage_step = resData.manage_step
    state.campaign.snapshot = resData
  }).catch(err => {
    state.hasLoadError = true
    turnOnErrorReasonModal(err)
  })

  const getTimelineList = () => api.getTimelineList({
    expg_idx: state.idx,
  }).then(response => {
    state.timelineList = response.data.data
  }).catch(err => {
    makeToast("danger", "타임라인 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getAdv = () => api.getAdv({
    expg_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.adv.idx = resData.idx
    state.adv.name = resData.name
    state.adv.company_name = resData.company_name
  }).catch(err => {
    makeToast("danger", "사용자 정보를 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getProduct = () => api.getProduct({
    expg_idx: state.idx,
  }).then(response => {
    const resData = response.data.data
    state.product.idx = resData.idx
    state.product.hashtag_list = resData.hashtag_list
    state.product.caution_text = resData.caution_text
    state.product.category_idx = resData.category_idx
    state.product.parent_category = resData.parent_category
    state.product.child_category = resData.child_category
    state.product.name = resData.name
    state.product.price = resData.price
    state.product.origin_price = resData.origin_price
    state.product.tagby_fee = resData.tagby_fee
    state.product.vat = resData.vat
    state.product.quantity = resData.quantity
    state.product.old_quantity = resData.quantity
    state.product.detail_img_idx = resData.detail_img_idx
    state.product.detail_text = resData.detail_text
    state.product.delivery_method = resData.delivery_method
    state.product.delivery_days = resData.delivery_days
    state.product.delivery_fee = resData.delivery_fee
    state.product.additional_delivery_fee = resData.additional_delivery_fee
    state.product.shipment_place_idx = resData.shipment_place_idx
    state.product.after_service_phone = resData.after_service_phone
    state.product.after_service_info = resData.after_service_info
    state.product.origin_info_json = resData.origin_info_json
    state.product.detail_img_has_origin_info = resData.detail_img_has_origin_info
    state.product.state = resData.state
    state.product.has_select_option = resData.has_select_option
    state.product.delivery_info_text = resData.delivery_info_text
    state.product.additional_shipping_fee_cond.jeju = resData.additional_shipping_fee_cond?.jeju
    state.product.additional_shipping_fee_cond.island_mountain = resData.additional_shipping_fee_cond?.island_mountain
    state.product.verify_hashtag = resData.verify_hashtag
    state.snapshot = resData
  }).catch(err => {
    makeToast("danger", "상품 정보를 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getShipmentPlaceList = () => api.getShipmentPlaceList({
    expg_idx: state.idx,
  }).then(response => {
    state.shipmentPlaceList = response.data.data
  }).catch(err => {
    makeToast("danger", "출고지 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getOptionList = () => api.getOptionList({
    expg_idx: state.idx,
  }).then(response => {
    state.optionList = response.data.data
  }).catch(err => {
    makeToast("danger", "상품 옵션 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getOrderList = () => api.getOrderList({
    expg_idx: state.idx,
  }).then(response => {
    state.orderList = response.data.data
  }).catch(err => {
    makeToast("danger", "참여 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getChatRoomList = () => api.getChatRoomList({
    campaign_idx: state.idx,
  }).then(response => {
    state.chatRoomList = response.data.data
  }).catch(err => {
    makeToast("danger", "채팅방 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getInfList = () => api.getInfList({
    expg_idx: state.idx,
  }).then(response => {
    state.infList = response.data.data
  }).catch(err => {
    makeToast("danger", "인플루언서 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getSnsList = () => api.getSnsList({
    expg_idx: state.idx,
  }).then(response => {
    state.snsList = response.data.data
  }).catch(err => {
    makeToast("danger", "SNS 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getPostingList = () => api.getPostingList({
    expg_idx: state.idx,
  }).then(response => {
    state.postingList = response.data.data
  }).catch(err => {
    makeToast("danger", "포스팅 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getCategoryList = () => api.getCategoryList().then(response => {
    state.childCategoryOptions = response.data.data
  }).catch(err => {
    makeToast("danger", "카테고리 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getFileList = () => api.getFileList({
    expg_idx: state.idx,
  }).then(response => {
    state.fileByIdx = indexing(response.data.data, "idx")
  }).catch(err => {
    makeToast("danger", "이미지 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  const getOptionMeta = () => api.getOptionMeta({
    expg_idx: state.idx,
  }).then(response => {
    state.optionMeta = response.data.data
  }).catch(err => {
    makeToast("danger", "상품 옵션 정보를 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  return {
    getAdminMe,
    getDetail,
    getTimelineList,
    getAdv,
    getProduct,
    getShipmentPlaceList,
    getOptionList,
    getOrderList,
    getChatRoomList,
    getInfList,
    getSnsList,
    getCategoryList,
    getFileList,
    getOptionMeta,
    getPostingList,
  }
}
