<template>
  <BRow>
    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BFormGroup>
        <BFormSelect
          v-model="state.orderTable.fieldsId"
          :options="fieldGroupList"
        />
      </BFormGroup>
    </BCol>

    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BFormGroup>
        <BFormCheckbox
          v-model="state.orderTable.selectable"
          switch
        >
          행 선택
        </BFormCheckbox>
      </BFormGroup>
    </BCol>

    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BDropdown
        variant="outline-primary"
        :text="`Actions(${selectedItemLength}개)`"
      >
        <!-- <BDropdownItem
          :disabled="isSetPaidOrderDisabled"
          @click="turnOnSetPaidOrderModal"
        >
          입금완료
        </BDropdownItem> -->

        <BDropdownItem
          :disabled="isCancelOrderDisabled"
          @click="turnOnCancelOrderModal"
        >
          주문취소
        </BDropdownItem>
      </BDropdown>
    </BCol>

    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <div class="text-danger">
        * cmd(ctrl): 링크 이동
      </div>
      <div class="text-danger">
        * option(alt): 텍스트 복사
      </div>
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import useOrderTable from '../../useOrderTable'
import state from '../../state'
import useSetPaidOrderModal from '../../useSetPaidOrderModal'
import useCancelOrderModal from '../../useCancelOrderModal'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const {
      fieldGroupList,
    } = useOrderTable()

    const selectedItemLength = computed(() => {
      const { tableRef } = state.orderTable
      if (tableRef == null) {
        return 0
      }
      const selectedItemList = tableRef.items.filter((_, i) => tableRef.isRowSelected(i))
      return selectedItemList.length
    })

    const {
      isDisabled: isSetPaidOrderDisabled,
      turnOnModal: turnOnSetPaidOrderModal,
    } = useSetPaidOrderModal()

    const {
      isDisabled: isCancelOrderDisabled,
      turnOnModal: turnOnCancelOrderModal,
    } = useCancelOrderModal()

    return {
      state,
      fieldGroupList,
      selectedItemLength,
      isSetPaidOrderDisabled,
      turnOnSetPaidOrderModal,
      isCancelOrderDisabled,
      turnOnCancelOrderModal,

    }
  },
})
</script>
