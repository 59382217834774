<template>
  <div>
    <ShipmentInfoCard />
    <ShipmentPlaceTable />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import ShipmentInfoCard from './ShipmentInfoCard.vue'
import ShipmentPlaceTable from './ShipmentPlaceTable.vue'

export default defineComponent({
  components: {
    ShipmentInfoCard,
    ShipmentPlaceTable,
  },
  setup() {

  },
})
</script>
