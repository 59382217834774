<template>
  <div>
    <PriceCard />
    <SelectOptionCard />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import PriceCard from './PriceCard.vue'
import SelectOptionCard from './SelectOptionCard.vue'

export default defineComponent({
  components: {
    PriceCard,
    SelectOptionCard,
  },
  setup() {

  },
})
</script>
