<template>
  <BModal
    :visible="state.cancelOrderModal.isVisible"
    modal-class="modal-danger"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="주문 취소"
    @hidden="turnOffModal"
  >
    <div>
      <div>
        주문 취소를 실행하시겠습니까?
      </div>
      <!-- <div class="text-danger">
        - 금액 환불
      </div>
      <div class="text-danger">
        - 선정 취소
      </div>
      <div class="text-danger">
        - 인플루언서의 경우 알림 발송
      </div> -->
      <div class="text-info">
        인플루언서에게 알림 발송없음
      </div>
    </div>

    <div v-if="state.cancelOrderModal.step !== 'READY'">
      <BProgress
        class="mb-1 mt-1"
        height="1.5rem"
        :value="state.cancelOrderModal.completedCount"
        :max="state.cancelOrderModal.targetCount"
        show-progress
        animated
      />
      <BFormTextarea
        :value="progressingText"
        readonly
      />
    </div>

    <template #modal-footer>
      <div
        v-if="state.cancelOrderModal.step === 'COMPLETE'"
        class="d-flex justify-content-end"
      >
        <BButton
          variant="outline-secondary"
          @click="turnOffModal"
        >
          닫기
        </BButton>
      </div>
      <div
        v-else
        class="d-flex justify-content-end"
      >
        <BButton
          variant="outline-secondary mr-1"
          @click="turnOffModal"
        >
          취소
        </BButton>
        <BButton
          variant="danger"
          :disabled="state.cancelOrderModal.step === 'PROGRESSING'"
          @click="cancelOrderList"
        >
          <BSpinner
            v-if="state.cancelOrderModal.step === 'PROGRESSING'"
            small
          />
          확인
        </BButton>
      </div>
    </template>
  </BModal>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BModal,
  BButton,
  BSpinner,
  BProgress,
  BFormTextarea,
} from 'bootstrap-vue'
import state from '../../state'
import useCancelOrderModal from '../../useCancelOrderModal'

export default defineComponent({
  components: {
    BModal,
    BButton,
    BSpinner,
    BProgress,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffModal,
      cancelOrderList,
    } = useCancelOrderModal()

    const progressingText = computed(() => {
      const textList = state.cancelOrderModal.progressingList.reduce((p, c) => [...p, c.join(' ')], ['Result Logging...'])
      return textList.join('\n')
    })

    return {
      state,
      turnOffModal,
      cancelOrderList,
      progressingText,
    }
  },
})
</script>
