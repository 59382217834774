<template>
  <div>
    <PostingFilter />
    <TableManage />
    <PostingTable />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import PostingFilter from './PostingFilter.vue'
import PostingTable from './PostingTable.vue'
import TableManage from './TableManage.vue'
import state from '../../state'
import usePostingTable from '../../usePostingTable'

export default defineComponent({
  components: {
    BCard,
    BTable,
    PostingFilter,
    PostingTable,
    TableManage,
  },
  setup() {
    const {
      fieldGroupList,
    } = usePostingTable()

    state.postingTable.fieldsId = fieldGroupList.value[0].value
  },
})
</script>
