<template>
  <BCard class="mb-1">
    <BRow>
      <BCol
        cols="12"
        md="6"
      >
        <BFormGroup label="SEARCH">
          <BFormInput
            v-model="state.postingFilter.searchText"
            placeholder="검색할 내용을 입력해주세요"
          />
        </BFormGroup>
      </BCol>
      <BCol
        cols="12"
      >
        <BFormGroup
          label="STATE"
        >
          <BFormCheckboxGroup
            v-model="state.postingFilter.state"
            :options="postingStateOptions"
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormInput,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'
import useResolveValue from '@/utils/resolveValue'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormInput,
  },
  setup() {
    const {
      resolveMoney,
    } = useResolveValue()

    const postingStateOptions = [
      'NOT_POSTING',
      'WAITING',
      'REGISTERED',
      'CONFIRMED',
      'SKIP',
      'DELETED',
    ]
    return {
      state,
      getters,
      postingStateOptions,
      resolveMoney,
    }
  },
})
</script>
