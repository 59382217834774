import { computed } from '@vue/composition-api'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getOrderList,
    getDetail,
    getTimelineList,
  } = useFetch()

  const all = arr => arr.reduce((p, c) => p && Boolean(c), true)

  const selectedItemList = computed(() => {
    const { tableRef } = state.orderTable
    if (tableRef == null) {
      return []
    }
    return tableRef.items.filter((_, i) => tableRef.isRowSelected(i))
  })

  const isDisabled = computed(() => {
    const isValidList = selectedItemList.value.map(order => ['ORDERED', 'PAID', 'READY', 'DELIVERY', 'DONE'].includes(order.state))
    if (isValidList.length === 0) {
      return true
    }
    return !all(isValidList)
  })

  const turnOnModal = () => {
    state.cancelOrderModal.step = 'READY'
    state.cancelOrderModal.isVisible = true
  }
  const turnOffModal = () => {
    state.cancelOrderModal.isVisible = false
    state.cancelOrderModal.isFunctionCancel = true
  }
  // const cancelOrderList = () => api.cancelOrderList({
  //   expg_idx: state.idx,
  //   order_idx_list: selectedItemList.value.map(p => p.idx),
  //   allow_notification: state.allowNotification,
  // }).then(() => {
  //   makeToast('primary', '주문 취소에 성공했습니다')
  //   getOrderList()
  //   getDetail()
  //   getTimelineList()
  // }).catch(err => {
  //   makeToast('danger', '주문 취소에 실패했습니다')
  //   turnOnErrorReasonModal(err)
  // })
  const cancelOrderList = () => {
    state.cancelOrderModal.progressingList = []
    // state.cancelOrderModal.isSaving = true
    // state.cancelOrderModal.isVisibleProgressing = true
    state.cancelOrderModal.step = 'PROGRESSING'
    state.cancelOrderModal.isFunctionCancel = false
    state.cancelOrderModal.completedCount = 0
    state.cancelOrderModal.targetCount = selectedItemList.value.length
    const resultList = selectedItemList.value.map(order => new Promise(resolve => {
      if (state.cancelOrderModal.isFunctionCancel) {
        state.cancelOrderModal.completedCount += 1
        resolve()
      } else {
        api.cancelOrder({
          order_idx: order.idx,
        }).then(() => {
          state.cancelOrderModal.progressingList.push([order.idx, 'SUCCESS'])
        }).catch(() => {
          state.cancelOrderModal.progressingList.push([order.idx, 'FAIL'])
        }).finally(() => {
          state.cancelOrderModal.completedCount += 1
          resolve()
        })
      }
    }))
    Promise.all(resultList).then(() => {
      makeToast('primary', '주문 취소에 성공했습니다')
    }).finally(() => {
      getOrderList()
      getDetail()
      getTimelineList()
      // state.cancelOrderModal.isSaving = false
      state.cancelOrderModal.step = 'COMPLETE'
    })
  }

  return {
    isDisabled,
    turnOnModal,
    turnOffModal,
    cancelOrderList,
  }
}
