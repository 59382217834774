<template>
  <BRow>
    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BFormGroup>
        <BFormSelect
          v-model="state.postingTable.fieldsId"
          :options="fieldGroupList"
        />
      </BFormGroup>
    </BCol>

    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BFormGroup>
        <BFormCheckbox
          v-model="state.postingTable.selectable"
          switch
        >
          행 선택
        </BFormCheckbox>
      </BFormGroup>
    </BCol>

    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <BDropdown
        variant="outline-primary"
        :text="`Actions(${selectedItemLength}개)`"
      >
        <BDropdownItem
          :disabled="IsSkipDisabled"
          @click="turnOnSkipModal"
        >
          포스팅 스킵
        </BDropdownItem>

        <BDropdownItem
          :disabled="isRegisterDisabled"
          @click="turnOnRegisterSidebar"
        >
          포스팅 등록
        </BDropdownItem>

        <BDropdownItem
          :disabled="isConfirmDisabled"
          @click="turnOnConfirmModal"
        >
          포스팅 확인
        </BDropdownItem>

        <BDropdownItem
          :disabled="isResetDisabled"
          @click="turnOnResetModal"
        >
          포스팅 리셋
        </BDropdownItem>
      </BDropdown>
    </BCol>

    <BCol
      md="3"
      sm="4"
      cols="6"
    >
      <div class="text-danger">
        * cmd(ctrl): 링크 이동
      </div>
      <div class="text-danger">
        * option(alt): 텍스트 복사
      </div>
    </BCol>
  </BRow>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormCheckbox,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import usePostingTable from '../../usePostingTable'
import state from '../../state'
import useSkipPostingModal from '../../useSkipPostingModal'
import useConfirmPostingModal from '../../useConfirmPostingModal'
import useRegisterPostingSidebar from '../../useRegisterPostingSidebar'
import useResetPostingModal from '../../useResetPostingModal'

export default defineComponent({
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
  setup() {
    const {
      fieldGroupList,
    } = usePostingTable()

    const {
      isDisabled: IsSkipDisabled,
      turnOnModal: turnOnSkipModal,
    } = useSkipPostingModal()

    const {
      isDisabled: isConfirmDisabled,
      turnOnModal: turnOnConfirmModal,
    } = useConfirmPostingModal()

    const {
      isDisabled: isRegisterDisabled,
      turnOnSidebar: turnOnRegisterSidebar,
    } = useRegisterPostingSidebar()

    const {
      isDisabled: isResetDisabled,
      turnOnModal: turnOnResetModal,
    } = useResetPostingModal()

    const selectedItemLength = computed(() => {
      const { tableRef } = state.postingTable
      if (tableRef == null) {
        return 0
      }
      const selectedItemList = tableRef.items.filter((_, i) => tableRef.isRowSelected(i))
      return selectedItemList.length
    })

    return {
      state,
      fieldGroupList,
      IsSkipDisabled,
      turnOnSkipModal,
      isConfirmDisabled,
      turnOnConfirmModal,
      isRegisterDisabled,
      turnOnRegisterSidebar,
      isResetDisabled,
      turnOnResetModal,
      selectedItemLength,
    }
  },
})
</script>
