<template>
  <div>
    <AdvCard />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import AdvCard from './AdvCard.vue'

export default defineComponent({
  components: {
    AdvCard,
  },
  setup() {

  },
})
</script>
