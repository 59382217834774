<template>
  <BDropdown
    variant="link"
    no-caret
  >
    <template #button-content>
      <FeatherIcon
        icon="MoreVerticalIcon"
        size="16"
        class="align-middle text-body"
      />
    </template>

    <slot />
  </BDropdown>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BDropdown,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BDropdown,
  },
  setup() {

  },
})
</script>
