export default function (func, delay = 0) {
  let timer = null
  return (...args) => {
    const context = this
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => func.apply(context, ...args), delay)
  }
}
