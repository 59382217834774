<template>
  <BDropdownItem
    :disabled="disabled"
    @click="$emit('click')"
  >
    <FeatherIcon :icon="icon" />
    <span class="align-middle ml-50">{{ text }}</span>
  </BDropdownItem>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BDropdownItem,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BDropdownItem,
  },
  emits: [
    'click',
  ],
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {

  },
})
</script>
