import api from "../api"
import state from "../state"
import useToast from "@/utils/toast"
import useErrorReason from "../useErroReason"

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErrorReason()

  const clickChatRoom = chatRoom => api.getChatList({
    room_idx: chatRoom.idx,
  }).then(response => {
    state.chatList = response.data.data
  }).catch(err => {
    makeToast("danger", "채팅 목록을 가져오는데 실패했습니다")
    turnOnErrorReasonModal(err)
  })

  return {
    clickChatRoom,
  }
}
