import state from './state'

export default () => {
  const inputProductVerifyHashtag = value => {
    state.product.verify_hashtag = !value
    if (value) {
      state.product.hashtag_list = null
    }
  }

  return {
    inputProductVerifyHashtag,
  }
}
