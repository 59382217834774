<template>
  <BInputGroup
    size="sm"
    class="p-1"
  >
    <BFormInput
      :value="text"
      type="search"
      placeholder="Type to Search"
      @input="$emit('input', $event)"
    />
  </BInputGroup>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BInputGroup,
  BFormInput,
} from "bootstrap-vue"

export default defineComponent({
  model: {
    prop: "text",
    event: "input",
  },
  components: {
    BInputGroup,
    BFormInput,
  },
  emits: ["input"],
  props: {
    text: String,
  },
  setup() {
  },
})
</script>
