<template>
  <div>
    <OrderFilter />
    <TableManage />
    <OrderTable />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import OrderFilter from './OrderFilter.vue'
import OrderTable from './OrderTable.vue'
import TableManage from './TableManage.vue'
import state from '../../state'
import useOrderTable from '../../useOrderTable'

export default defineComponent({
  components: {
    BCard,
    BTable,
    OrderFilter,
    OrderTable,
    TableManage,
  },
  setup() {
    const {
      fieldGroupList,
    } = useOrderTable()

    state.orderTable.fieldsId = fieldGroupList.value[0].value
  },
})
</script>
