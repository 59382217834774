<template>
  <Cleave
    :value="value"
    class="form-control"
    :raw="false"
    :options="{
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    }"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="$emit('input', parseToNumber($event))"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.us'

export default defineComponent({
  components: {
    Cleave,
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: Number,
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'input',
  ],
  setup() {
    const parseToNumber = strValue => {
      const replacedValue = strValue.replaceAll(',', '')
      if (!replacedValue) {
        return 0
      }
      const numberValue = parseInt(replacedValue, 10)
      return numberValue
    }

    return {
      parseToNumber,
    }
  },
})
</script>
