import {
  computed,
  reactive,
} from '@vue/composition-api'

import state from './state'

import { indexing } from '@/utils/value'

const isCreatingMode = computed(() => !state.idx)

const thumbImgObj = computed(() => {
  const fileIdx = state.campaign.thumb_img_idx
  const file = state.fileByIdx[fileIdx]
  return file
})

const detailImgObj = computed(() => {
  const fileIdx = state.product.detail_img_idx
  const file = state.fileByIdx[fileIdx]
  return file
})

const childCategoryOptions = computed(() => state.childCategoryOptions.map(opt => ({
  label: opt.class_2,
  value: opt.idx,
})))

const tagbyFee = computed(() => {
  const productPrice = state.product.price ?? 0
  return Math.round(productPrice * 0.12)
})

const optionWithMeta = computed(() => state.optionList.map(opt => {
  const valueWithMeta = opt.value.map((v, i) => ({
    category: state.optionMeta[i]?.name,
    value: v,
  }))
  return {
    idx: opt.idx,
    quantity: opt.quantity,
    state: opt.state,
    extra_price: opt.extra_price,
    tagby_fee: Math.round(opt.extra_price * 0.12),
    values: valueWithMeta,
  }
}))

const snsByIdx = computed(() => indexing(state.snsList, 'idx'))
const infByIdx = computed(() => indexing(state.infList, 'idx'))

const getOrderFilter = () => {
  const orderItems = computed(() => state.orderList.map(o => {
    const sns = snsByIdx.value[o.sns_idx]
    return {
      ...o,
      sns,
      service: sns?.service,
    }
  }).map(o => {
    const inf = infByIdx.value[o.member_idx]
    return {
      ...o,
      inf,
    }
  }).filter(o => {
    if (!state.orderFilter.searchText) {
      return true
    }
    if (
      o.sns?.username.includes(state.orderFilter.searchText)
      || o.sns?.uid.includes(state.orderFilter.searchText)
    ) {
      return true
    }
    return o.order_no.includes(state.orderFilter.searchText)
  }).filter(o => {
    if (!state.orderFilter.needSettled) {
      return true
    }
    const availabelState = ['PAID', 'READY', 'DELIVERY', 'DONE']
    return !o.is_settled && availabelState.includes(o.state)
  })
    .filter(o => {
      if (!state.orderFilter.state?.length) {
        return true
      }
      return state.orderFilter.state.includes(o.state)
    }))
  const orderFrom = computed(() => {
    const { perPage, currentPage } = state.orderFilter
    return (currentPage - 1) * perPage + 1
  })
  const orderOf = computed(() => orderItems.value.length)
  const orderTo = computed(() => {
    const { perPage } = state.orderFilter
    const endRow = orderFrom.value + perPage
    if (endRow < orderOf.value) {
      return endRow
    }
    return orderOf.value
  })
  return {
    items: orderItems,
    from: orderFrom,
    of: orderOf,
    to: orderTo,
  }
}
const orderFilter = getOrderFilter()

const getAmount = targetOrderList => {
  const priceList = targetOrderList.map(order => {
    const productJson = order.product_json
    const optionJson = order.option_json

    const { price } = productJson
    const shippingFee = productJson.delivery_fee ?? 0
    const additionalShippingFee = productJson.additional_delivery_fee ?? 0
    const productTagbyFee = productJson.tagby_fee ?? 0
    const productCardFee = productJson.card_fee ?? 0

    const extraPrice = optionJson?.extra_price ?? 0
    const optionTagbyFee = optionJson?.tagby_fee ?? 0
    const optionCardFee = optionJson?.card_fee ?? 0

    const amount = price + shippingFee + additionalShippingFee + extraPrice
    const fee = productTagbyFee + productCardFee + optionTagbyFee + optionCardFee

    return amount - fee
  })
  return priceList.reduce((p, c) => p + c, 0)
}

const getSettleOrderSidebar = () => {
  const orderNoList = computed(() => {
    if (!state.settleOrderSidebar.text) {
      return []
    }
    return state.settleOrderSidebar.text.split('\n')
  })

  const amount = computed(() => {
    const targetOrderList = orderFilter.items.filter(o => orderNoList.value.includes(o.order_no))
    return getAmount(targetOrderList)
  })

  const invalidOrderNoList = computed(() => state.settleOrderSidebar.invalidOrderNoList?.join('\n'))
  return {
    orderNoList,
    amount,
    invalidOrderNoList,
  }
}
const settleOrderSidebar = getSettleOrderSidebar()

const amountAvailableForSettlement = computed(() => {
  const targetOrderList = state.orderList.filter(o => {
    const availabelState = ['PAID', 'READY', 'DELIVERY', 'DONE']
    return availabelState.includes(o.state) && !o.is_settled
  })
  return getAmount(targetOrderList)
})

const getPostingFilter = () => {
  const postingItems = computed(() => state.postingList.map(o => {
    const sns = snsByIdx.value[o.sns_idx]
    return {
      ...o,
      sns,
    }
  }).map(o => {
    const inf = infByIdx.value[o.inf_idx]
    return {
      ...o,
      inf,
    }
  }).filter(o => {
    if (!state.postingFilter.searchText) {
      return true
    }
    if (
      o.sns?.username.includes(state.postingFilter.searchText)
      || o.sns?.uid.includes(state.postingFilter.searchText)
    ) {
      return true
    }
    return o.url.includes(state.postingFilter.searchText)
  }).filter(o => {
    if (!state.postingFilter.state?.length) {
      return true
    }
    return state.postingFilter.state.includes(o.state)
  }))
  const postingFrom = computed(() => {
    const { perPage, currentPage } = state.postingFilter
    return (currentPage - 1) * perPage + 1
  })
  const postingOf = computed(() => postingItems.value.length)
  const postingTo = computed(() => {
    const { perPage } = state.postingFilter
    const endRow = postingFrom.value + perPage
    if (endRow < postingOf.value) {
      return endRow
    }
    return postingOf.value
  })
  return {
    items: postingItems,
    from: postingFrom,
    of: postingOf,
    to: postingTo,
  }
}
const postingFilter = getPostingFilter()

export default reactive({
  isCreatingMode,
  thumbImgObj,
  detailImgObj,
  childCategoryOptions,
  tagbyFee,
  optionWithMeta,
  orderFilter,
  settleOrderSidebar,
  amountAvailableForSettlement,
  postingFilter,
})
