<template>
  <BCard title="가격">
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="정가">
          <TagbyMoneyInput
            v-model="state.product.origin_price"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="체험 판매가">
          <TagbyMoneyInput
            v-model="state.product.price"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="태그바이 수수료">
          <TagbyMoneyInput
            :value="getters.tagbyFee"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'
import TagbyMoneyInput from '@/components/TagbyMoneyInput.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    TagbyMoneyInput,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
