<template>
  <TagbyActionButtonV2
    v-if="!getters.isCreatingMode"
    :isSaving="state.updateDetail.isSaving"
    :isValid="true"
    variant="outline-primary"
    text="저장"
    @click="updateDetail"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import TagbyActionButtonV2 from '@/components/TagbyActionButtonV2.vue'
import state from '../../state'
import getters from '../../getters'
import useUpdateDetail from '../../useUpdateDetail'

export default defineComponent({
  components: {
    TagbyActionButtonV2,
  },
  setup() {
    const {
      updateDetail,
    } = useUpdateDetail()

    return {
      state,
      getters,
      updateDetail,
    }
  },
})
</script>
