import { computed } from '@vue/composition-api'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getPostingList,
    getDetail,
    getTimelineList,
  } = useFetch()

  const all = arr => arr.reduce((p, c) => p && Boolean(c), true)

  const selectedItemList = computed(() => {
    const { tableRef } = state.postingTable
    if (tableRef == null) {
      return []
    }
    return tableRef.items.filter((_, i) => tableRef.isRowSelected(i))
  })

  const isDisabled = computed(() => {
    const isValidList = selectedItemList.value.map(posting => ['NOT_POSTING'].includes(posting.state))
    if (isValidList.length === 0) {
      return true
    }
    return !all(isValidList)
  })

  const turnOnSidebar = () => {
    state.registerPostingSidebar.isVisible = true
    state.registerPostingSidebar.selectedItemList = selectedItemList.value.map(e => ({ ...e }))
  }
  const turnOffSidebar = () => {
    state.registerPostingSidebar.isVisible = false
  }
  const registerPostingList = () => {
    state.registerPostingSidebar.isSaving = true
    api.registerPostingList({
      expg_idx: state.idx,
      url_dict_list: state.registerPostingSidebar.selectedItemList.map(posting => ({
        idx: posting.idx,
        url: posting.url,
      })),
    }).then(() => {
      makeToast('primary', '포스팅 등록에 성공했습니다')
      getPostingList()
      getDetail()
      getTimelineList()
    }).catch(err => {
      makeToast('danger', '포스팅 등록에 실패했습니다')
      turnOnErrorReasonModal(err)
    }).finally(() => {
      state.registerPostingSidebar.isSaving = false
      turnOffSidebar()
    })
  }

  const isValid = computed(() => {
    const isValidList = state.registerPostingSidebar.selectedItemList.map(posting => Boolean(posting.url))
    return all(isValidList)
  })

  const inputUrl = (idx, value) => {
    state.registerPostingSidebar.selectedItemList = state.registerPostingSidebar.selectedItemList.map(posting => {
      if (posting.idx === idx) {
        return {
          ...posting,
          url: value,
        }
      }
      return posting
    })
  }

  return {
    isDisabled,
    turnOnSidebar,
    turnOffSidebar,
    registerPostingList,
    isValid,
    inputUrl,
  }
}
