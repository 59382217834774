<template>
  <BCard title="사용자 정보">
    <BRow>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="IDX">
          <TagbyLinkInput
            :value="state.adv.idx"
            :to="{name: 'member-adv-view', params: {idx: state.adv.idx}}"
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="이름">
          <BFormInput
            :value="state.adv.name"
            readonly
          />
        </BFormGroup>
      </BCol>
      <BCol
        v-if="!getters.isCreatingMode"
        cols="12"
        sm="6"
        md="4"
      >
        <BFormGroup label="회사명">
          <BFormInput
            :value="state.adv.company_name"
            readonly
          />
        </BFormGroup>
      </BCol>
    </BRow>
  </BCard>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import TagbyLinkInput from '@/components/TagbyLinkInput.vue'
import state from '../../state'
import getters from '../../getters'

export default defineComponent({
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TagbyLinkInput,
  },
  setup() {
    return {
      state,
      getters,
    }
  },
})
</script>
