<template>
  <BAvatarGroup size="32px">
    <BAvatar
      v-if="channel.includes('INSTA')"
      rounded
      variant="warning"
      class="pull-up"
    >
      <span>INS</span>
    </BAvatar>
    <BAvatar
      v-else-if="channel.includes('NAVER')"
      rounded
      variant="success"
      class="pull-up"
    >
      <span>BLG</span>
    </BAvatar>
  </BAvatarGroup>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BAvatarGroup,
  BAvatar,
} from 'bootstrap-vue'

export default defineComponent({
  components: {
    BAvatarGroup,
    BAvatar,
  },
  props: {
    channel: {
      type: String,
      required: true,
    },
  },
  setup() {
  },
})
</script>
