<template>
  <ManageImgSidebar
    :isVisible="state.manageThumbImgSidebar.isVisible"
    :imgObj="getters.thumbImgObj"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
    @ok="changeFile"
  />
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import state from '../../state'
import getters from '../../getters'
import ManageImgSidebar from './ManageImgSidebar.vue'
import useManageThumbImgSidebar from '../../useManageThumbImgSidebar'

export default defineComponent({
  components: {
    ManageImgSidebar,
  },
  setup() {
    const {
      turnOffSidebar,
      changeFile,
    } = useManageThumbImgSidebar()

    return {
      state,
      getters,
      turnOffSidebar,
      changeFile,
    }
  },
})
</script>
