import { computed } from '@vue/composition-api'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getOrderList,
    getDetail,
    getTimelineList,
  } = useFetch()

  const all = arr => arr.reduce((p, c) => p && Boolean(c), true)

  const selectedItemList = computed(() => {
    const { tableRef } = state.orderTable
    if (tableRef == null) {
      return []
    }
    return tableRef.items.filter((_, i) => tableRef.isRowSelected(i))
  })

  const isDisabled = computed(() => {
    const isValidList = selectedItemList.value.map(order => ['ORDERED'].includes(order.state))
    if (isValidList.length === 0) {
      return true
    }
    return !all(isValidList)
  })

  const turnOnModal = () => {
    state.setPaidOrderModal.isVisible = true
  }
  const turnOffModal = () => {
    state.setPaidOrderModal.isVisible = false
  }
  const setPaidOrderList = () => api.setPaidOrderList({
    expg_idx: state.idx,
    order_idx_list: selectedItemList.value.map(p => p.idx),
    allow_notification: state.allowNotification,
  }).then(() => {
    makeToast('primary', '주문 입금완료 처리에 성공했습니다')
    getOrderList()
    getDetail()
    getTimelineList()
  }).catch(err => {
    makeToast('danger', '주문 입금완료 처리에 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  return {
    isDisabled,
    turnOnModal,
    turnOffModal,
    setPaidOrderList,
  }
}
