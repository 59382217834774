import { computed } from '@vue/composition-api'
import state from './state'
import api from './api'
import useToast from '@/utils/toast'
import useErroReason from './useErroReason'
import useFetch from './useFetch'

export default () => {
  const {
    makeToast,
  } = useToast()

  const {
    turnOnErrorReasonModal,
  } = useErroReason()

  const {
    getPostingList,
    getDetail,
    getTimelineList,
  } = useFetch()

  const all = arr => arr.reduce((p, c) => p && Boolean(c), true)

  const selectedItemList = computed(() => {
    const { tableRef } = state.postingTable
    if (tableRef == null) {
      return []
    }
    return tableRef.items.filter((_, i) => tableRef.isRowSelected(i))
  })

  const isDisabled = computed(() => {
    const isValidList = selectedItemList.value.map(posting => ['NOT_POSTING', 'NOT_CERT', 'REGISTERED', 'CONFIRMED', 'SKIP'].includes(posting.state))
    if (isValidList.length === 0) {
      return true
    }
    return !all(isValidList)
  })

  const turnOnModal = () => {
    state.resetPostingModal.isVisible = true
  }
  const turnOffModal = () => {
    state.resetPostingModal.isVisible = false
  }
  const resetPostingList = () => api.resetPostingList({
    expg_idx: state.idx,
    posting_idx_list: selectedItemList.value.map(p => p.idx),
  }).then(() => {
    makeToast('primary', '포스팅 리셋에 성공했습니다')
    getPostingList()
    getDetail()
    getTimelineList()
  }).catch(err => {
    makeToast('danger', '포스팅 리셋에 실패했습니다')
    turnOnErrorReasonModal(err)
  })

  return {
    isDisabled,
    turnOnModal,
    turnOffModal,
    resetPostingList,
  }
}
