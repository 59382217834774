<template>
  <div>
    <h5>출고지</h5>
    <BCard no-body>
      <BTable
        :items="state.shipmentPlaceList"
        :fields="fields"
        show-empty
        responsive
        small
      >
        <template #cell(address)="data">
          <TagbyConditionalLink
            :stateList="['TEXT', 'COPY']"
            :text="getAddress(data.item)"
          />
        </template>

        <template #cell(updated_at)="data">
          <TagbyDatetimeColumn :value="data.value" />
        </template>
      </BTable>
    </BCard>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import state from '../../state'
import getters from '../../getters'
import TagbyConditionalLink from '@/components/TagbyConditionalLink.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    BCard,
    BTable,
    TagbyConditionalLink,
    TagbyDatetimeColumn,
  },
  setup() {
    const fields = [
      { key: 'idx', label: 'idx' },
      { key: 'address', label: '주소' },
      { key: 'phone', label: '연락처' },
      { key: 'state', label: '상태' },
      { key: 'updated_at', label: '수정일시' },
    ]

    const getAddress = item => {
      if (!item.address_detail) {
        return item.address
      }
      return `${item.address} ${item.address_detail}`
    }

    return {
      state,
      getters,
      fields,
      getAddress,
    }
  },
})
</script>
