<template>
  <TagbySidebarWithButton
    :visible="state.settleOrderSidebar.isVisible"
    :isSaving="state.settleOrderSidebar.isSaving"
    :isValid="state.settleOrderSidebar.isValid"
    title="정산"
    width="30%"
    @ok="settleOrder"
    @hidden="turnOffSidebar"
    @cancel="turnOffSidebar"
  >
    <!-- <TagbyInputRow label="주문번호">
    </TagbyInputRow> -->
    <BRow>
      <BCol>
        <BFormGroup
          label="주문번호"
          :invalid-feedback="`주문번호 목록을 확인하세요\n${getters.settleOrderSidebar.invalidOrderNoList}`"
        >
          <BFormTextarea
            v-model="state.settleOrderSidebar.text"
            placeholder="줄바꿈을 사용해 정산을 진행할 주문번호 목록을 적어주세요"
            rows="5"
            :state="state.settleOrderSidebar.isValid"
            @input="inputText"
          />

        </BFormGroup>
      </BCol>
    </BRow>

    <TagbyInputRow label="정산액">
      <TagbyMoneyInput
        v-model="state.settleOrderSidebar.settlementAmount"
      />
    </TagbyInputRow>
  </TagbySidebarWithButton>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BFormTextarea,
  BFormGroup,
  BRow,
  BCol,
} from 'bootstrap-vue'
import TagbySidebarWithButton from '@/components/TagbySidebarWithButton.vue'
import TagbyInputRow from '@/components/TagbyInputRow.vue'
import TagbyMoneyInput from '@/components/TagbyMoneyInput.vue'
import state from '../../state'
import getters from '../../getters'
import useSettleOrderSidebar from '../../useSettleOrderSidebar'

export default defineComponent({
  components: {
    TagbySidebarWithButton,
    TagbyInputRow,
    TagbyMoneyInput,
    BFormGroup,
    BRow,
    BCol,
    BFormTextarea,
  },
  setup() {
    const {
      turnOffSidebar,
      settleOrder,
      inputText,
    } = useSettleOrderSidebar()

    return {
      state,
      getters,
      turnOffSidebar,
      settleOrder,
      inputText,
    }
  },
})
</script>
