<template>
  <div
    class="h-100 mt-1 mr-1 mb-1 my-chat-block"
    :class="[computedSkin]"
  >
    <div
      class="p-1 overflow-auto"
      style="max-height: 470px"
    >
      <div
        v-for="chat in chats"
        :key="chat.idx"
      >
        <ChatLeft
          v-if="chat.member_type === 'INF'"
          :chat="chat"
          :isNotRead="getIsNotRead(advReadAt, chat.created_at)"
        />
        <ChatRight
          v-else
          :chat="chat"
          :isNotRead="getIsNotRead(infReadAt, chat.created_at)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "@vue/composition-api"
import moment from "moment"
import store from "@/store"
import ChatLeft from "./components/ChatLeft.vue"
import ChatRight from "./components/ChatRight.vue"

export default defineComponent({
  components: {
    ChatLeft,
    ChatRight,
  },
  props: {
    chats: Array,
    advReadAt: String,
    infReadAt: String,
  },
  setup() {
    const computedSkin = computed(() => store.state.appConfig.layout.skin)
    const getIsNotRead = (readAt, createdAt) => moment(readAt) < moment(createdAt)

    return {
      computedSkin,
      getIsNotRead,
    }
  },
})
</script>

<style scoped>
.my-chat-block.dark {
  background-color: #161d31!important;
}
.my-chat-block.light {
  background-color: var(--light)!important;
}
</style>
